import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import $ from 'jquery's
import Storage from './assets/js/storage.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import VueTouch from 'vue-touch'
import 'default-passive-events'
Vue.use(ElementUI, axios)
Vue.use(preview)
Vue.use(VueTouch, { name: 'v-touch' })
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$qiniuUrl = 'http://resource.wangpeishi.org.cn/'
Vue.prototype.$price = '2500元'
Vue.prototype.Storage = Storage

axios.interceptors.request.use(
  config => {
    if (
      Storage.getStorage('token') &&
      config.url != 'https://edc.org.cn/api/TeaUnionCerts' &&
      config.url != 'https://edc.org.cn/api/WpsUserCerts' &&
      config.url != 'http://api.wangpeishi.org.cn/api/article' &&
      config.url != 'http://api.wangpeishi.org.cn/api/articleInfoNotice/s' &&
      config.url != 'http://api.wangpeishi.org.cn/api/articleInfoNEws/'
    ) {
      config.headers.Authorization = 'Bearer ' + Storage.getStorage('token')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
// http response 服务器响应拦截器，这里拦截401错误，并重新跳入登页重新获取token
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      if (error.response.status == 401) {
        localStorage.removeItem('token')
        setTimeout(function() {
          location.href = 'http://www.wangpeishi.org.cn/login'
        }, 2000)
      }
    }
    // return Promise.reject(error.response.data)
  }
)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
