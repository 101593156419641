<template>
    <div class="PersonalNav">
        <div class="header">
            <div class="header_cont">
                <el-row :gutter="20" type="flex" justify="center" align="middle">
                    <el-col :span="3">
                        <img src="../../../assets/images/home/mback.png" alt="" class="leftimg" @click="back">
                    </el-col>
                    <el-col :span="16">
                        <img src="../../../assets/images/home/userlogo.png" alt srcset class="logo"  @click="tohome"/>
                    </el-col>
                    <el-col :span="3">
                        <img src="../../../assets/images/home/umenu.png" alt="" class="rightimg"  @click="openmenu">
                        <!-- <span class="txtmenu"  @click.stop="openmenu">菜单</span> -->
                    </el-col>
                </el-row>
            </div>
        </div>
        <div style="height:50px"></div>
        <transition name="fade">
            <div class="menu" v-show="menushow">
                <el-menu :default-active="path" class="menu" :router=true @select="handleSelect"> 
                    <el-menu-item index="userinfo">
                        <span slot="title">个人资料</span>
                    </el-menu-item>
                    <el-menu-item index="myachievement">
                        <span slot="title">我的成绩</span>
                    </el-menu-item>
                    <el-menu-item index="mycertificate">
                        <span slot="title">我的证书</span>
                    </el-menu-item>
                    <el-menu-item @click="logout">
                        <span slot="title">切换账号</span>
                    </el-menu-item>
                </el-menu>
            </div>
        </transition>
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            menushow:false,
            path:""
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        openmenu(){
            this.menushow=!this.menushow
            this.path=this.$route.path.substring(this.$route.path.lastIndexOf('/')+1)
        },
        handleSelect(){
            this.menushow=false
        },
        back(){
            this.$router.go(-1)
        },
        tohome(){
            this.$router.push({ path: '/home' })
        },
        logout() {
            this.$store.commit('setUser', {})
            this.Storage.delStorage('token')
            this.$router.push({ path: '/login' })
        },
        init(){
            document.addEventListener('click',(e)=>{
                let thisClassName=e.target.className
                if(thisClassName!="rightimg"){
                    this.menushow=false
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .header{
        height: 50px;
        position: fixed;
        z-index: 99;
        box-shadow: 0 0 8px #ccc;
    }
    .header_cont{
        height: 50px;
        display: flex;
        z-index: -1;
        background-color: #960F23;
        /deep/.el-row{
            margin: 0px!important;
        }
        .leftimg{
            position: relative;
            top: 5%;
            right: 45%;
            width: 75%;
        }
        .rightimg{
            position: relative;
            top: 5%;
            left: 65%
        }
        .logo{
            position: relative;
            bottom: 3px;
            opacity: 0%;
        }
        .txtmenu{
            white-space:nowrap;
            position: relative;
            bottom: 3px;
            color: #FFFFFF;
            font-size: 17px;
            font-weight: bold;
        }
    }
    .menu{
        position:absolute;
        right: 0px;
        width: 28vw;
        z-index:99;
        background-color: #333333;
        /deep/.el-menu-item{
            height: 40px;
            line-height: 40px;
            color: #FFFFFF;
        }
        /deep/.el-menu-item:hover{
            background-color: #292929;
            color: #960f23;
        }
        /deep/.el-menu-item.is-active{
            color: #960f23;
            background-color: #292929;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to{
        opacity: 0;
    }
</style>