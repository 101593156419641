import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'
import UserCenter from '../views/layout/userCenter.vue'
import Home from '../views/Home.vue'
import mobiles from './mobile'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

function isMobile() {
  var ua = navigator.userAgent
  var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
    isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
    isAndroid = ua.match(/(Android)\s+([\d.]+)/),
    isMobile = isIphone || isAndroid
  if (isMobile) {
    return true
  }
  return false
}

var ismob = isMobile()

var routes = [
  {
    path: '/micrologin/:number?',
    name: 'MicroLogin',
    component: () => import('../views/MicroClass/MicroLogin.vue'),
    meta: { title: '推荐人登录' },
  },
  {
    path: '/microlist',
    name: 'MicroList',
    component: () => import('../views/MicroClass/MicroList.vue'),
    meta: { title: '推荐人查询' },
  },
  {
    path: '/userCenter',
    redirect: { path: '/userCenter/info' },
  },
  {
    path: '/userCenter',
    name: 'UserCenter',
    component: UserCenter,
    children: [
      {
        path: '/userCenter/:id',
        name: 'UserInfo',
        component: () => import('../views/UserCenter.vue'),
        meta: { title: '网培师-用户中心' },
      },
    ],
  },
  {
    path: '/',
    // name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'home/:agent?',
        name: 'Home',
        component: Home,
        meta: { title: '网培师 官网' },
      },
      {
        path: 'news',
        name: 'News',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/News.vue'),
        meta: { title: '网培师-新闻资讯' },
      },
      {
        path: 'newsdesc',
        name: 'NewsDesc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/NewsDesc.vue'),
        meta: { title: '网培师-资讯详情' },
      },
      {
        path: 'certificate',
        name: 'Certificate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Certificate.vue'),
        meta: { title: '网培师-认证体系' },
      },
      {
        path: 'gcertificate',
        name: 'GCertificate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/GCertificate.vue'),
        meta: { title: '网培师-认证体系' },
      },
      {
        path: 'guide',
        name: 'Guide',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Guide.vue'),
        meta: { title: '网培师-报考指南' },
      },
      {
        path: 'syllabus',
        name: 'Syllabus',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Syllabus.vue'),
        meta: { title: '网培师-报考指南' },
      },
      {
        path: 'invigilator',
        name: 'Invigilator',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Invigilator.vue'),
        meta: { title: '网培师-报考指南' },
      },
      {
        path: 'login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login.vue'),
        meta: { title: '网培师-登录' },
      },
      {
        path: 'regist/:agent?',
        name: 'Regist',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Regist.vue'),
        meta: { title: '网培师-注册' },
      },
      {
        path: 'reset',
        name: 'Reset',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/ResetPwd.vue'),
        meta: { title: '网培师-重置密码' },
      },
      {
        path: 'evaluationnotes',
        name: 'Evaluationnotes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Evaluationnotes.vue'),
        meta: { title: '网培师-评测须知' },
      },
      {
        path: 'simulation',
        name: 'Simulation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Simulation.vue'),
        meta: { title: '网培师-模拟练习' },
      },
      {
        path: 'examination',
        name: 'Examination',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Examination.vue'),
        meta: { title: '网培师-参加考试' },
      },
      {
        path: 'traincourse',
        name: 'Traincourse',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Traincourse.vue'),
        meta: { title: '网培师-培训课程' },
      },
      {
        path: 'firstpart',
        name: 'FirstPart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/FirstPart.vue'),
        meta: { title: '网培师-在线评测' },
      },
      {
        path: 'secondpart',
        name: 'SecondPart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/SecondPart.vue'),
        meta: { title: '网培师-在线评测' },
      },
      {
        path: 'thirdpart',
        name: 'ThirdPart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/ThirdPart.vue'),
        meta: { title: '网培师-在线评测' },
      },
      {
        path: 'people',
        name: 'People',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Peoples.vue'),
        meta: { title: '网培师-名企人材库' },
      },
      {
        path: 'certsearch',
        name: 'CertSearch',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/CertSearch.vue'),
        meta: { title: '网培师-证书发放' },
      },
      {
        path: 'certinfo',
        name: 'CertInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/CertInfo.vue'),
        meta: { title: '网培师-证书发放' },
      },
      {
        path: 'exam/:id',
        name: 'Exam',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Exam.vue'),
        meta: { title: '网培师-评测' },
      },
      {
        path: 'xy',
        name: 'Xy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Xy.vue'),
        meta: { title: '网培师-平台协议' },
      },
      {
        path: 'question',
        name: 'Question',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Question.vue'),
        meta: { title: '网培师网站QA常见问题' },
      },
      {
        path: '/:agent?',
        name: 'Index',
        component: Home,
      },
    ],
  },
]

if (ismob) {
  routes = mobiles
}

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
