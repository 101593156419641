<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view />
    <!-- <el-backtop style="color:#fff; background-color:#960f23" v-show="!ismo">
      
    </el-backtop> -->
    <div class="suspensionBtn" v-show="!ismo">
      <div @click="tobacktop" :class="{ appear: top >= 200, noappear: top < 200 }">
        <img src="./assets/images/home/tobacktop.png" alt="" />
      </div>
      <div @click="tosignupnow" v-if="signupshow">
        <img src="./assets/images/home/signupnow.png" alt="" />
      </div>
      <div @click="commonproblem">
        <img src="./assets/images/home/commonproblem.png" alt="" />
      </div>
      <el-tooltip placement="left-start" effect="light">
        <div slot="content" class="tooltips">
          <p class="service txt_cen">网培师客服微信</p>
          <img src="./assets/images/user/service.png" alt="" />
          <p class="txt_cen">客服热线：19542727521</p>
        </div>
        <div>
          <img src="./assets/images/home/serviceimg.png" alt="" />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
let timer = null;
let _this;
export default {
  data() {
    return {
      ismo: false,
      top:0,
      signupshow: false,
    };
  },
  created() {
    _this = this;
    _this.verification();
    this.ismo = this.isMobile();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    isMobile() {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      if (isMobile) {
        return true;
      }
      return false;
    },
    handleScroll() {
      this.top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    tobacktop() {
      timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0 || this.osTop < 0) {
          clearInterval(timer);
        }
      }, 18);
    },
    tosignupnow() {
      // window.location.href="/traincourse"
      this.$router.push("/traincourse");
    },
    commonproblem() {
      this.$router.push("/question");
    },
    //登录和支付状态判断
    verification() {
      if (this.$store.state.wps_user.mobile) {
        _this.$http
          .post(process.env.VUE_APP_URL + "isPay", { good_id: 1 })
          .then((data) => {
            this.token = data.data.token;
            if (data.data.code == 403) {
              _this.$message.error(data.data.msg);
              return;
            }
            if (data.data.code == 200) {
              _this.signupshow = true;
            } else {
              _this.signupshow = false;
            }
          })
          .catch((err) => {
            // _this.$message.error('网络错误，请重试')
            console.log(err);
          });
      } else {
        _this.signupshow = true;
      }
    },
  },
  watch: {
    $route: "verification"
  },
  beforeDestroy(){
    alert(1)
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
    alert(1)
  },
  
};
</script>
<style lang="less">
@import "./common/css/base.css";
@import "./common/css/common.less";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
// @media screen and (max-width:960px){
//   #app{
//     min-width: 1200px!important;
//   }
// }
.el-button--danger {
  background: #960f23 !important;
}
/deep/ i .el-icon-caret-top {
  border: #960f23 !important;
  color: #960f23 !important;
}
.font_red {
  color: red;
}
.suspensionBtn {
  display: block;
  z-index: 1000;
  position: fixed;
  bottom: 100px;
  right: 50px;
  transition: opacity 0.5s;
  img {
    width: 70px;
    height: 70px;
  }
}
.appear {
  display: block;
  img {
    cursor: pointer;
  }
  animation-name: fadeIn; /*动画名称*/
  animation-duration: 0.3s; /*动画持续时间*/
  animation-iteration-count: 1; /*动画次数*/
  animation-delay: 0s; /*延迟时间*/
}
.noappear{
  display: none;
}
.tooltips{
  p{
    font-size: 12px;
    line-height: 20px;
  }
  img{
    width: 150px;
    height: 150px;
  }
  .service{
    font-size: 16px;
  }
  .txt_cen{
    text-align: center;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0; /*初始状态 透明度为0*/
  }
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
  }
}
.el-tooltip__popper.is-light{
  border: none!important;
  box-shadow: 0px 0px 5px 1px #e8e8e8;
}
.el-tooltip__popper[x-placement^=left-start] .popper__arrow {
    border: none;
    box-shadow: 0px 0px 5px 1px #242323;
}
</style>
