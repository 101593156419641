import Vue from 'vue'
import Vuex from 'vuex'
import Storage from '../assets/js/storage.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wps_user:Storage.getStorage('wps_user')||{},
  },
  getters: {
    getUser: state => {
      return state.wps_user
    }
  },
  mutations: {
    setUser:(state,data) => {
      state.wps_user=data;
      Storage.setStorage('wps_user',data);
    },
  },
  actions: {
  },
  modules: {
  }
})
