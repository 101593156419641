import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMobile from '../views/mobile/layout/Navigation.vue'
import PersonalNav from '../views/mobile/layout/PersonalNav.vue'
import Home from '../views/mobile/Home.vue'

Vue.use(VueRouter)

const mobiles = [
    {
        path:'/mobile/usercenter',
        component:PersonalNav,
        children:[
            {
            path:'',
            name:'MUsercenter',
            redirect:'userinfo',
            meta: { title: '网培师-用户中心'},
            },
            {
            path:'userinfo',
            name:'MUserInfo',
            component: () => import('../views/mobile/UserInfo.vue'),
            meta: { title: '网培师-用户中心'},
            },
            {
            path:'myachievement',
            name:'MMyAchievement',
            component: () => import('../views/mobile/MyAchievement.vue'),
            meta: { title: '网培师-我的成绩'},
            },
            {
            path:'mycertificate',
            name:'MMyCertificate',
            component: () => import('../views/mobile/MyCertificate.vue'),
            meta: { title: '网培师-用户中心'},
            }
        ]
    },
    {
        path:'/',
        component:LayoutMobile,
        children:[
            {
            path:'home/:agent?',
            name:'MHome',
            component:Home,
            meta: { title: '网培师 官网' },
            },
            {
            path:'guide',
            name:'MGuide',
            component: () => import('../views/mobile/Guide.vue'),
            meta: { title: '网培师-报考指南' },
            },
            {
            path:'invigilator',
            name:'MInvigilator',
            component: () => import('../views/mobile/Invigilator.vue'),
            meta: { title: '网培师-远程指挥监考' },
            },
            {
            path:'certificate',
            name:'MCertificate',
            component: () => import('../views/mobile/Certificate.vue'),
            meta: { title: '网培师-证书发放' },
            },
            {
                path:'traincourse',
                name:'MTraincourse',
                component: () => import('../views/mobile/Traincourse.vue'),
                meta: { title: '网培师-培训课程' },
            },
            {
            path:'peoples',
            name:'MPeoples',
            component: () => import('../views/mobile/Peoples.vue'),
            meta: { title: '网培师-名企人才库' },
            },
            {
            path:'login',
            name:'MLogin',
            component: () => import('../views/mobile/Login.vue'),
            meta: { title: '网培师-登录' },
            },
            {
            path:'register/:agent?',
            name:'MRegister',
            component: () => import('../views/mobile/Register.vue'),
            meta: { title: '网培师-注册' },
            },
            {
            path:'reset',
            name:'MReset',
            component: () => import('../views/mobile/Reset.vue'),
            meta: { title: '网培师-重置密码' },
            },
            {
            path:'webmaster',
            name:'MWebmaster',
            component: () => import('../views/mobile/Webmaster.vue'),
            meta: { title: '网培师-什么是网培师' },
            },
            {
                path:'agreement',
                name:'MAgreement',
                component: () => import('../views/mobile/Agreement.vue'),
                meta: { title: '网培师-平台协议' },
            },
            {
                path:'newsdesc',
                name:'MNewsDesc',
                component: () => import('../views/mobile/NewsDesc.vue'),
                meta: { title: '网培师-行业资讯' },
            },
            {
                path:'/:agent?',
                name:'MIndex',
                component:Home,
                meta: { title: '网培师 官网' },
            },
        ]
    },
]


export default mobiles