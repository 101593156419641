<template>
  <div class="home">
    <div class="banner">
      <img
        src="../../assets/images/home/banner.png"
        alt=""
        class="banner_img"
        @click="examination"
      />
    </div>
    <div class="news">
      <el-tabs v-model="activeTab" stretch>
        <el-tab-pane label="行业要闻" name="first">
          <div class="xw" v-for="(item, index) in Newslist" :key="index">
            <el-row v-if="item.type == 2">
              <el-col :span="24">
                <img :src="newstipimg[index]" class="newstip" />
                <p class="cont" @click="newsInfo(item.id, item.type)">
                  {{ item.name }}
                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="平台公告" name="second">
          <div class="xw" v-for="(item, index) in Noticelist" :key="index">
            <el-row>
              <el-col :span="24">
                <img :src="newstipimg[index]" class="newstip" />
                <p class="cont" @click="newsInfo(item.id, item.type)">
                  <i></i>{{ item.name }}
                </p>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <div class="qrcode">
        <h2>参加课程</h2>
        <div class="qr_img">
            <img src="../../assets/images/home/qrcode.png" alt="" preview >
        </div>
        <div class="qr_p">
            <p>点击按钮或扫描二维码</p>
            <p>交费后可查看所有学习资料</p>
        </div>
        <div class="text-center">
            <el-button type="danger" class="go" @click="course">查看课程目录>></el-button>
            <el-button type="danger" class="go" @click="study">前往学习课程>></el-button>
        </div>
    </div> -->
    <!-- <div class="Suspended" @click="study">
      <p>前往<br>课程</p>
    </div> -->

    <div class="what">
      <h2>什么是网培师</h2>
      <div class="what_txt">
        <p>网培师，指依托互联网平台从事培训咨询和教学活动的人员。</p>
        <p>
          是在推进在线教育发展及推出在线教育相关新职业岗位的背景下，由中国商业联合会在国内设立的专业能力评价认证项目。网培师的标准建设及平台服务，由上海及相关省市的培训协会、人才服务行业协会、以及网班教育等多家专业组织和机构共同参与，受到众多教培机构的欢迎。
        </p>
      </div>
      <div class="text-center">
        <el-button type="danger" class="go" @click="details"
          >查看详情>></el-button
        >
      </div>
    </div>

    <div class="why">
      <h2>为什么成为网培师</h2>
      <el-card class="box_card">
        <el-carousel
          :interval="8000"
          arrow="always"
          indicator-position="outside"
          height="160px"
          ref="carousel1"
          v-touch:swipeleft="leftSlide"
          v-touch:swiperight="rightSlide"
        >
          <el-carousel-item v-for="(item, index) in carousels1" :key="index">
            <div class="flex-col-center">
              <img class="icon" :src="item.url" alt srcset />
              <strong>{{ item.title }}</strong>
            </div>
            <p>{{ item.cont }}</p>
          </el-carousel-item>
        </el-carousel>
      </el-card>
    </div>

    <div class="advantage">
      <h2>网培师岗位合格培训证书优势</h2>
      <div class="advantage_img">
        <img src="../../assets/images/home/certificate.jpg" alt="" />
      </div>
      <el-card class="box_card">
        <p>1.&nbsp;评审通过后，本证书将由“中国商业联合会"颁发。</p>
        <p>
          2.&nbsp;本证书表明，持有者通过岗位能力评测并考核合格，具备了相应的专业知识和技能，可作为聘用、考核和能力评价的参考依据，国内通用。
        </p>
        <p>
          3.&nbsp;本证书序列号具有唯一性，并基于教育区块链学习银行记录证书，此证书具有数据安全可信、不可篡改等特点，且易于携带、保存和转发等特点。
        </p>
        <p>
          4.&nbsp;网培师项目由上海市培训协会、上海人才服务行业协会、上海现代服务业联合会互联网科创专委会和网班教育评测中心共同发起，经中国商业联合会批准设立的职业能力认证项目。
        </p>
      </el-card>
    </div>

    <div class="evaluating">
      <h2>在线评测模块</h2>
      <el-carousel
        type="card"
        height="375px"
        :autoplay="false"
        ref="carousel2"
        v-touch:swipeleft="left"
        v-touch:swiperight="right"
      >
        <el-carousel-item v-for="(item, index) in carousels2" :key="index">
          <el-card class="box_card">
            <img :src="item.url" alt="" />
            <h4 class="text-center">{{ item.title }}</h4>
            <p>{{ item.cont }}</p>
          </el-card>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- <div class="comments">
        <h2>学员评价</h2>
        <el-carousel height="380px" :interval=5000 ref="carousel3"
          v-touch:swipeleft="commentleft"
          v-touch:swiperight="commentright">
          <el-carousel-item v-for="(item,index) in comments" :key="index">
            <el-card class="box-card">
              <div class="flex-col-center comment_box">
                  <div class="comment_img">
                    <img :src="item[0].img" alt="">
                  </div>
                  <p>{{item[0].comment}}</p>
              </div>
            </el-card>
            <el-card class="box-card">
              <div class="flex-col-center comment_box">
                  <div class="comment_img">
                    <img :src="item[1].img" alt="">
                  </div>
                  <p>{{item[1].comment}}</p>
              </div>
            </el-card>
            <el-card class="box-card">
              <div class="flex-col-center comment_box">
                  <div class="comment_img">
                    <img :src="item[2].img" alt="">
                  </div>
                  <p>{{item[2].comment}}</p>
              </div>
            </el-card>
          </el-carousel-item>
        </el-carousel>
    </div> -->

    <div class="bg advantage">
      <h2>网培师项目背景</h2>
      <div class="advantage_img">
        <img src="../../assets/images/home/img6.jpg" alt="" />
      </div>
      <el-card class="box_card">
        <p>
          2019年9月30日，《关于促进在线教育健康发展的指导意见》，明确指出，到2020年，大幅提升在线教育的基础设施建设水平，互联网、大数据、人工智能等现代信息技术在教育领域的应用更加广泛、在线教育模式更加完善，资源和服务更加丰富。到2022年，现代信息技术与教育实现深度融合，在线教育质量不断提升，资源和服务标准体系全面建立，学习型社会建设取得重要进展。
          2020年7月，由人力资源社会保障部联合市场监管总局、统计局近日正式向社会发布一批新职业，包括：“在线学习服务师”、“互联网营销师”、“区块链工程技术人员”、“老年人能力评估师”等9个新职业。这是我国自《中华人民共和国职业分类大典（2015年版）》颁布以来发布的第三批新职业。
        </p>
      </el-card>
    </div>

    <div class="footer">
      <div class="text-center">
        <a href="tel:021-61806588-8003" class="el-icon-phone tel"
          >021-61806588-8003</a
        >
      </div>
      <div class="text-center service">
        <a href="tel:19542727521">客服热线：19542727521</a>
      </div>
      <div class="f_img">
        <img src="../../assets/images/home/logo.png" alt="" />
      </div>
      <p>沪ICP备17018503号-4 版权所有 上海网班网培师认证</p>
    </div>
    <el-dialog
      :visible.sync="showclass"
      title="课程目录"
      class="dialog_table"
      top="10vh"
      width="120vh"
    >
      <div class="container">
        <Classtable></Classtable>
      </div>
    </el-dialog>
    <!-- 支付弹框 -->
    <!-- <el-dialog title="支付" :visible.sync="dialogVisible" width="85vw" v-loading="loading" :close-on-click-modal="false" center class="payStyle">
        <div>
            <span>付费内容:</span>
            培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
        </div>
        <div v-if="payRadio !== '1'">
            <span>输入兑换码: </span>
            <el-input placeholder="请输入兑换码" class="redeem" v-model="redeemData"></el-input>
            <p class="warnRed">*请注意区分大小写</p>
        </div>
        <div v-else><span>支付金额: </span>{{ this.$price }}</div>
        <div>
            <p><span>支付方式:</span></p>
            <el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
            <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
        </div>
        <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
            >立即支付</el-button>
        <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
    </el-dialog> -->
  </div>
</template>
<script>
let _this
import Classtable from '../../components/Classtable'
export default {
  components: { Classtable },
  data() {
    return {
      activeTab: 'first',
      carousels1: [
        {
          url: require('../../assets/images/home/Icon1.png'),
          title: '就业前景好',
          cont:
            '国内首家对在线教育岗位的认证平台，与多家名企机构和院校合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。被称为网络教学的必备证书！',
        },
        {
          url: require('../../assets/images/home/Icon2.png'),
          title: '全面教学能力和实操技能提升',
          cont:
            '网培师认证评测内容包括：教师综合能力评测、网培政策规范评测、教学数字化能力评测；学习期间可培养个人的教学能力，网培相关政策法规能力及制作教学课件的能力。',
        },
        {
          url: require('../../assets/images/home/icon3 (2).png'),
          title: '一体化拿证流程',
          cont:
            '了解岗位、报名付费、模拟考试练习、在线考试、获得证书，本平台提供一体化的整个流程服务！',
        },
        {
          url: require('../../assets/images/home/icon4 (2).png'),
          title: '国际认证',
          cont:
            '网培师能力水平评价项目由中国商业联合会和上海现代服务业联合会互联网科创服务专委会分别批准，并得到联合国训研所UNITAR中国中心的认可并达成正式合作，参与培训及认证的网培师可申请进入联合国训研所的官方人才库。',
        },
      ],
      carousels2: [
        {
          url: require('../../assets/images/home/img7.png'),
          title: '教学综合能力评测',
          cont:
            '本模块旨在考核被评测者是否具备教学综合能力，对被评测者的教学基础知识掌握情况以及在案例中的应用熟练程度进行测量和评定。',
        },
        {
          url: require('../../assets/images/home/img8.png'),
          title: '网培政策规范评测',
          cont:
            '本模块旨在考核被评测者是否了解和掌握在我国从事网络培训工作需要具备的相关法律规范，以及涉及到计算机网络安全、知识产权、文明上网等相关法规。',
        },
        {
          url: require('../../assets/images/home/img9.png'),
          title: '教学数字化能力评测',
          cont:
            '本模块旨在考核被评测者在网络培训教学中数字化能力水平，考察被评测者对常用的教学数字化软件工具（幻灯片、图片和视频简单处理）的掌握熟练程度。',
        },
      ],
      comments: [
        [
          {
            img: require('../../assets/images/home/headportraits/headportrait1.png'),
            comment: '课程还行，刷题功能蛮好的很流畅。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait2.png'),
            comment:
              '学会了一些课件制作的技巧，还有网络教学过程中要注意的政策规范。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait3.png'),
            comment: '因为读了教师资格，迎合目前需求，再增加点线上的教学技能。',
          },
        ],
        [
          {
            img: require('../../assets/images/home/headportraits/headportrait4.png'),
            comment:
              '第一次只看了课件，没刷题考不出来，第二次刷了题就容易很多。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait5.png'),
            comment: '第一个模块，就是我考教师资格证的题目。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait6.png'),
            comment: '这个课缺少教学方法，就觉得学到些政策法规。',
          },
        ],
        [
          {
            img: require('../../assets/images/home/headportraits/headportrait7.png'),
            comment:
              '早知道在考教师资格证前先来考这个，刷刷题对考教师资格蛮有用的，如果读过教师资格证了读这个意义好像不大。除非为了多张证',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait8.png'),
            comment: '这个课程能不能做得再精致点，感觉教程没问题，但是有点乱。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait9.png'),
            comment: '想通过考试还是主要靠刷题，光看课程没什么用。',
          },
        ],
        [
          {
            img: require('../../assets/images/home/headportraits/headportrait10.png'),
            comment:
              '和教师资格证有点重合，而且含金量不如教师资格证，但是容易点，如果教资考不上，先学这个打点基础也不错。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait11.png'),
            comment: '课程里有教剪辑和修图的教学，还蛮有意思的，能提升技能。',
          },
          {
            img: require('../../assets/images/home/headportraits/headportrait12.png'),
            comment: '如果想从事网络在线教学的话，可以增加在线教学知识。',
          },
        ],
      ],
      newstipimg: [
        require('../../assets/images/home/newstip/newtip1.png'),
        require('../../assets/images/home/newstip/newtip2.png'),
        require('../../assets/images/home/newstip/newtip3.png'),
        require('../../assets/images/home/newstip/newtip4.png'),
        require('../../assets/images/home/newstip/newtip5.png'),
      ],
      Newslist: [],
      Noticelist: [],
      showclass: false,
      // dialogVisible: false,
      // studyed: false,
      // loading: false,
      // payRadio: '1',
      // form: {},
      // obj: {},
      // redeemData: '',
      // token:''
    }
  },
  created() {
    _this = this
    this.obj = this.$store.state.wps_user
    if (_this.$route.params.agent) {
      document.cookie = 'agent=' + escape(_this.$route.params.agent.trim())
    }
    this.init()
  },
  // mounted(){
  //     this.silde()
  // },
  methods: {
    init() {
      _this.$http
        .get('http://api.wangpeishi.org.cn/api/article')
        .then(data => {
          if (data.data.code == 200) {
            var list = data.data.data
            for (var i = 0; i < list.length; i++) {
              if (list[i].type == 2) {
                _this.Newslist.push(list[i])
              } else {
                _this.Noticelist.push(list[i])
              }
            }
            _this.Newslist = _this.Newslist.slice(0, 5)
            _this.Noticelist = _this.Noticelist.slice(0, 5)
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    newsInfo(id, type) {
      this.$router.push({
        path: '/newsDesc',
        query: {
          id: id,
          type: type,
        },
      })
    },
    examination() {
      this.$router.push({ path: '/traincourse' })
      // if (this.$store.state.wps_user.mobile) {
      //   this.$router.push({ path: "/mobile/usercenter" });
      // } else {
      //   this.$router.push({ path: "/login" });
      // }
    },
    course() {
      this.showclass = true
    },
    details() {
      this.$router.push({ path: '/webmaster' })
    },
    leftSlide() {
      this.$refs.carousel1.next()
    },
    rightSlide() {
      this.$refs.carousel1.prev()
    },
    left() {
      this.$refs.carousel2.next()
    },
    right() {
      this.$refs.carousel2.prev()
    },
    commentleft() {
      this.$refs.carousel3.next()
    },
    commentright() {
      this.$refs.carousel3.prev()
    },
    // study() {
    //     _this.studyed = true
    //     if (this.$store.state.wps_user.mobile) {
    //         _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
    //         .then(data => {
    //             this.token=data.data.token
    //             if (data.data.code == 403) {
    //                 _this.$message.error(data.data.msg)
    //                 return
    //             }
    //             if (data.data.code == 200) {
    //                 _this.dialogVisible = true
    //             } else {
    //                 _this.setNetbanCookie()
    //             }
    //         })
    //         .catch(err => {
    //             _this.$message.error('网络错误，请重试')
    //             console.log(err)
    //         })
    //     } else {
    //         this.$message.warning('您还未登录，请先登录！')
    //     }
    // },
    // setNetbanCookie() {
    //   _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
    //   .then(data => {
    //       _this.token=data.data.token
    //       if (_this.studyed) {
    //           window.location.href='http://netban.cn/api/user/synwp/' +
    //           _this.token +
    //           '?url=http://custom.netban.cn/app/?id=T20326'
    //       }
    //   })
    // },
    // toRedeem() {
    // //兑换码
    // _this.loading = true
    // _this.$http.post(process.env.VUE_APP_URL + 'exchange', {
    //       good_id: 1,
    //       numbers: this.redeemData,
    //     })
    //     .then(data => {
    //     _this.loading = false
    //     if (data.data.code == 403) {
    //         _this.$message.error(data.data.msg)
    //         return
    //     }
    //     if (data.data.code == 500) {
    //         _this.$message.error(data.data.msg)
    //         return
    //     }
    //       _this.setNetbanCookie()
    //       _this.dialogVisible = false
    //     })
    //     .catch(err => {
    //         _this.$message.error('网络错误，请重试')
    //         _this.loading = false
    //         console.log(err)
    //     })
    // },
    // toPay() {
    //     //去支付
    //     this.toExam()
    // },
    // toExam() {
    //     let subsystem_url = window.location.href
    //     _this.$http.post(process.env.VUE_APP_URL + 'order', {good_id: 1,subsystem_url: subsystem_url,})
    //     .then(data => {
    //         if (data.data.code == 200) {
    //             //未付款
    //             _this.order_number = data.data.data
    //             window.location.href =
    //             'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
    //             // _this.dialogVisible = true;
    //         } else if (data.data.code == 201&&data.data.token!='') {
    //             //已付款
    //               _this.setNetbanCookie()
    //         } else {
    //             _this.$message.error(data.data.msg)
    //         }
    //     }).catch(err => {
    //         _this.$message.error('网络错误，请重试')
    //         console.log(err)
    //     })
    // },
  },
  directives: {
    touch: {
      bind: function(el, binding) {
        var touchType = binding.arg //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0
        var direction = ''
        //滑动处理
        var startX, startY

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY
          var dx = endX - startX
          var result = 0

          //如果滑动距离太短
          if (Math.abs(dx) < 100 && Math.abs(dy) < 100) {
            return result
          }

          var angle = GetSlideAngle(dx, dy)
          if (angle >= -45 && angle < 45) {
            result = 'swiperight'
          } else if (angle >= 45 && angle < 135) {
            result = 'swipeup'
          } else if (angle >= -135 && angle < -45) {
            result = 'swipedown'
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = 'swipeleft'
          }
          return result
        }

        el.addEventListener(
          'touchstart',
          function(ev) {
            startX = ev.touches[0].pageX
            startY = ev.touches[0].pageY

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0
              if (touchType === 'press') {
                binding.value()
              }
            }, 500)
          },
          false
        )

        el.addEventListener('touchmove', function() {
          clearTimeout(timeOutEvent)
          timeOutEvent = 0
        })

        el.addEventListener(
          'touchend',
          function(ev) {
            var endX, endY
            endX = ev.changedTouches[0].pageX
            endY = ev.changedTouches[0].pageY
            direction = GetSlideDirection(startX, startY, endX, endY)
            clearTimeout(timeOutEvent)
            switch (direction) {
              case 0:
                break
              case 'swipeup':
                if (touchType === 'swipeup') {
                  binding.value()
                }
                break
              case 'swipedown':
                if (touchType === 'swipedown') {
                  binding.value()
                }
                break
              case 'swipeleft':
                if (touchType === 'swipeleft') {
                  binding.value()
                }
                break
              case 'swiperight':
                if (touchType === 'swiperight') {
                  binding.value()
                }
                break
              default:
            }
          },
          false
        )
      },
    },
  },
}
</script>
<style lang="less" scoped>
.banner .banner_img {
  width: 100%;
}
.news {
  background: #fbf8f5;
  border-radius: 0px 0px 5px 5px;
  /deep/.el-tabs__header {
    max-width: 100%;
  }
  /deep/.el-tabs__item {
    font-weight: bold;
    padding: 0px;
    font-size: 17px;
    font-family: Microsoft YaHei;
  }
  /deep/.el-tabs__nav.is-top {
    text-align: center;
  }
  /deep/.el-tabs__item.is-active {
    color: #960f23;
    border-bottom: 2px solid #960f23;
  }
  /deep/.el-tabs__active-bar {
    height: 0px;
  }
  /deep/.el-tabs__nav-wrap::after {
    display: none;
  }
  /deep/.el-tabs__nav-wrap {
    max-width: 200px;
    margin: 0px auto;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .source {
    font-size: 14px;
    color: #666666;
  }
  .cont {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /*下面三条缺一不可 可以生成 '...' 主要针对谷歌浏览器 */
    -webkit-line-clamp: 1; //表示行数
    -webkit-box-orient: vertical; //表示纵向
    margin-left: 5px;
  }
  .newstip {
    width: 25px;
    height: 25px;
  }
  .xw {
    padding: 0px 20px 1px 20px;
    .xw_img {
      width: 25vw;
      img {
        width: 100%;
      }
    }
    .xw_txt {
      margin-left: 10px;
    }
    .el-row {
      margin: 10px 0px !important;
    }
    .el-col-24 {
      display: flex;
      align-items: center;
    }
  }
  /deep/.el-tabs__content {
    padding-bottom: 12px;
  }
}
h2 {
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000005;
}
.Suspended {
  width: 50px;
  height: 50px;
  border: 1px solid #960f23;
  background-color: #960f23;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  z-index: 50;
  bottom: 30px;
  right: 10px;
  p {
    margin-top: 8px;
    color: #ffffff;
    font-size: 12px;
  }
}
.qrcode {
  width: 100%;
  padding: 30px 0px;
  background-color: #ffffff;

  .qr_img {
    width: 50vw;
    margin: 0px auto;
    img {
      width: 100%;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  .qr_p {
    margin-bottom: 20px;
  }
}
.go {
  height: 30px;
  background: #ce9a6c !important;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 0px 20px;
}
.what {
  width: 100%;
  background-color: #fbf8f5;
  padding: 20px 0px;
  .what_txt {
    width: 80vw;
    margin: 20px auto;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 20px;
    }
  }
}
.why {
  width: 100%;
  background: url('../../assets/images/home/why.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
  .box_card {
    width: 85vw;
    margin: 20px auto;
    /deep/.el-card__body {
      padding: 20px 20px 5px 20px;
    }
    /deep/.el-carousel__container {
      .icon {
        width: 8vw;
      }
      strong {
        font-size: 12px;
        font-family: Microsoft YaHei;
        margin-left: 5px;
      }
      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7c8087;
        line-height: 25px;
        margin-top: 5px;
      }
    }
    /deep/.el-carousel__arrow {
      display: none;
    }
  }
}
/deep/.el-carousel__indicator .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 100px;
}
.advantage {
  width: 100%;
  padding-top: 20px;
  .advantage_img {
    margin: 20px auto;
    width: 50vw;
    img {
      width: 100%;
      position: relative;
      z-index: 60;
    }
  }
  .box_card {
    width: 80vw;
    margin: 0px auto;
    padding-top: 40px;
    position: relative;
    top: -65px;
    p {
      margin-top: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 20px;
    }
  }
}
.fit {
  width: 100%;
  padding: 30px 0px;
  background-color: #fbf8f5;
  .fit_item {
    background: #ffffff;
    // border: 1px solid #CE9A6C;
    box-shadow: 0px 10px 35px 0px rgba(206, 154, 108, 0.17);
    border-radius: 6px;
    padding: 20px 10px;
    margin-top: 20px;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
}
.evaluating {
  padding: 15px 0px;
  img {
    width: 100%;
  }
  /deep/.el-carousel__container {
    margin-top: 20px;
  }
  /deep/.el-carousel__item.is-active {
    width: 85vw;
    transform: translateX(7.5vw) scale(1) !important;
    .el-card__body {
      padding: 15px;
    }
  }
  h4 {
    margin: 10px 0px;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7c8087;
    line-height: 20px;
  }
}
.comments {
  padding: 15px 0px;
  /deep/.el-carousel__arrow {
    display: none;
  }
  /deep/.el-carousel__indicators {
    display: none;
  }
  .box-card {
    width: 85vw;
    margin: 20px auto;
    /deep/.el-card__body {
      height: 80px;
      padding: 8px 20px;
    }
    .comment_box {
      height: 80px;
    }
    .comment_img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
    }
    p {
      margin-left: 10px;
      width: 70%;
      font-size: 12px;
    }
  }
}
.footer {
  padding: 20px 0px;
  background-color: #2b2b2b;
  width: 100%;
  .f_img {
    width: 60vw;
    margin: 0px auto;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 8px;
  }
  a {
    color: #ffffff;
    margin-bottom: 8px;
  }
  .service {
    margin-bottom: 10px;
  }
}
.dialog_table {
  .container {
    height: 70vh;
  }
  /deep/.el-dialog {
    width: 90% !important;
  }
  /deep/.el-dialog__header {
    text-align: center;
    font-weight: bold;
    color: #000000;
  }
  /deep/.el-dialog__body {
    padding: 0px;
  }
  /deep/.el-tabs__content {
    padding: 0px;
  }
}
// //弹出支付认证
// .el-dialog {
//     width: 80vw;
//     .el-input {
//       width: 270px;
//       margin: 10px 0;
//     }
//     .el-button--danger {
//       background-color: #960f23;
//       width: 100px;
//       margin-top: 50px;
//       margin-left:31%;
//     }
//   }
//   .uploading {
//     margin-bottom: 100px;
//   }
//   .redeem {
//     padding: 10px 10px 0 0;
//   }
//   .toRedeem {
//     width: 120px !important;
//     margin-left: 14px;
//   }
//   .cancel {
//     margin-right: 225px;
//   }
//   .examselect {
//     p {
//       margin: 12px;
//       font-size: 15px;
//       span {
//         color: red;
//       }
//     }
//     div {
//       padding-top: 20px;
//       .el-button {
//         width: 110px;
//       }
//     }
//   }
//   .el-radio {
//     margin: 15px 0px;
//   }
//   .download {
//     padding-top: 10px;
//     font-size: 15px;
//     .el-link {
//       color: red;
//       font-size: 16px;
//     }
//   }
//   .toPdf {
//     h2 {
//       margin-bottom: 25px;
//       font-size: 20px;
//       color: #960f23;
//     }
//     div {
//       margin: 5px;
//       font-size: 16px;
//     }
//   }
//   .dialog_table {
//     .container {
//       height: 70vh;
//     }
//     /deep/.el-dialog__header {
//       text-align: center;
//       font-weight: bold;
//       color: #000000;
//     }
//     /deep/.el-dialog__body {
//       padding: 10px 100px 20px;
//     }
//   }
//   /deep/.el-form-item{
//     margin: 0px;
//   }
//   .warnRed {
//     color: red;
//     font-size: 13px;
//   }
//   .payStyle {
//     div {
//       font-size: 15px;
//       margin: 10px;
//     }
//     span {
//       font-size: 16px;
//       color: #960f23;
//       padding-right: 10px;
//     }
//     .el-button {
//       margin: 30px 30%;
//     }
// }
// /deep/.el-form-item__label{
//       padding:10px 0px 0px!important;
//     }
// /deep/.el-form-item__error{
//   padding-top:0px;
// }
</style>
