var storage = {
	setStorage(key, val) {
		localStorage.setItem(key, JSON.stringify(val))
	},

	getStorage(key) {
		return JSON.parse(localStorage.getItem(key))
	},
	delStorage(key) {
		localStorage.removeItem(key);
	},
	languageTypeStorage() {
		let type = localStorage.getItem("languageType")
		if (type == 'zh' || type == 'en') {
			return type
		} else {
			localStorage.setItem('languageType', 'zh')
			return 'zh'
		}
	}

}

export default storage