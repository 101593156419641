<template>
  <div class="classtabel">
    <el-tabs v-model="activeTab" stretch>
      <!-- 教学综合能力评测 -->
      <el-tab-pane label="教学综合能力评测" name="first">
          <table1></table1>
      </el-tab-pane>
      <!-- 网培政策规范评测 -->
      <el-tab-pane label="网培政策规范评测" name="second">
          <table2></table2>
      </el-tab-pane>
      <!-- 教学数字化能力评测 -->
      <el-tab-pane label="教学数字化能力评测" name="third">
          <table3></table3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import table1 from './tabel/table1'
import table2 from './tabel/table2'
import table3 from './tabel/table3'
export default {
  data() {
    return {
      activeTab: 'first',
    }
  },
  components:{table1,table2,table3}
}
</script>
<style lang="less" scoped>
.classtabel {
  /deep/.el-tabs__item {
    font-size: 16px;
    color: #000000;
    padding-bottom: 20px;
  }
  /deep/.el-tabs__item.is-active {
    color: #960f23;
  }
  /deep/.el-tabs__active-bar {
    height: 3px;
    background-color: #960f23;
  }
  /deep/.el-tabs__content {
    position: absolute;
    top: 125px;
    bottom: 0;
    left: 30px;
    right: 30px;
    overflow-y: auto;
    padding: 0px 50px;
  }
}
p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #000000;
}
.tip {
  text-align: center;
  font-size: 14px;
  color: red;
}
</style>
