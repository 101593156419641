<template>
  <div class="Nav">
    <div class="header">
      <div class="header_cont">
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="3" class="logo_img">
            <img
              src="../../../assets/images/home/user.png"
              alt=""
              class="leftimg"
              @click="toCenter"
            />
          </el-col>
          <el-col :span="16" class="logo_img">
            <img
              src="../../../assets/images/logo.png"
              alt
              class="logo"
              @click="tohome"
            />
          </el-col>
          <el-col :span="3" class="logo_img">
            <img
              v-show="openmenuShow"
              src="../../../assets/images/home/menu.png"
              alt=""
              class="rightimg"
              @click="openmenu"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="height:50px"></div>
    <transition name="fade">
      <div class="menu" v-show="menushow">
        <el-menu
          :default-active="path"
          class="menu"
          :router="true"
          @select="handleSelect"
        >
          <el-menu-item index="/">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="guide">
            <span slot="title">报考须知</span>
          </el-menu-item>
          <el-menu-item index="invigilator">
            <span slot="title">智慧监考</span>
          </el-menu-item>
          <el-menu-item index="certificate">
            <span slot="title">证书发放</span>
          </el-menu-item>
          <el-menu-item index="traincourse">
            <span slot="title">培训课程</span>
          </el-menu-item>
          <el-menu-item index="peoples">
            <span slot="title">名企人才库</span>
          </el-menu-item>
        </el-menu>
      </div>
    </transition>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="Suspended" @click="study" v-if="Suspendedshow">
      <p>前往<br />课程</p>
    </div>
    <!-- 支付弹框 -->
    <div id="dialog">
      <el-dialog
        title="支付"
        :visible.sync="dialogVisible"
        width="85vw"
        v-loading="loading"
        :close-on-click-modal="false"
        center
        class="payStyle"
      >
        <div>
          <span>付费内容:</span>
          培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
        </div>
        <div v-if="payRadio !== '1'">
          <span>输入兑换码: </span>
          <el-input
            placeholder="请输入兑换码"
            class="redeem"
            v-model="redeemData"
          ></el-input>
          <p class="warnRed">*请注意区分大小写</p>
        </div>
        <div v-else><span>支付金额: </span>{{ this.$price }}</div>
        <div>
          <p><span>支付方式:</span></p>
          <el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
          <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
        </div>
        <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
          >立即支付</el-button
        >
        <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
let _this
export default {
  data() {
    return {
      menushow: false,
      path: '',
      dialogVisible: false,
      studyed: false,
      loading: false,
      payRadio: '1',
      form: {},
      obj: {},
      redeemData: '',
      token: '',
      Suspendedshow: true,
      openmenuShow: false,
    }
  },
  created() {
    _this = this
    let r_path = _this.$route.path
    if (r_path.indexOf('register') < 0) {
      _this.openmenuShow = true
    }
    this.obj = this.$store.state.wps_user
    _this.Storage.setStorage('agent', _this.$route.params.agent)
    var Sus = this.$route
    if (
      Sus.name == 'MLogin' ||
      Sus.name == 'MRegister' ||
      Sus.name == 'MAgreement' ||
      Sus.name == 'MReset'
    ) {
      this.Suspendedshow = false
    } else {
      this.Suspendedshow = true
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    $route(to) {
      if (
        to.name == 'MLogin' ||
        to.name == 'MRegister' ||
        to.name == 'MAgreement' ||
        to.name == 'MReset'
      ) {
        this.Suspendedshow = false
      } else {
        this.Suspendedshow = true
      }
    },
  },
  methods: {
    openmenu() {
      this.menushow = !this.menushow
      this.path = this.$route.path.substring(
        this.$route.path.lastIndexOf('/') + 1
      )
      if (this.path == '' || this.path == 'home') {
        this.path = '/'
      }
    },
    handleSelect() {
      this.menushow = false
    },
    toCenter() {
      if (this.$store.state.wps_user.mobile) {
        this.$router.push({ path: '/mobile/usercenter/userinfo' })
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    tohome() {
      this.$router.push({ path: '/' })
    },
    init() {
      document.addEventListener('click', e => {
        let thisClassName = e.target.className
        if (thisClassName != 'rightimg') {
          this.menushow = false
        }
      })
    },
    study() {
      _this.studyed = true
      if (this.$store.state.wps_user.mobile) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            this.token = data.data.token
            if (data.data.code == 403) {
              _this.$message.error(data.data.msg)
              return
            }
            if (data.data.code == 200) {
              _this.dialogVisible = true
            } else {
              _this.setNetbanCookie()
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$confirm('需要登录，请先登录账号', '提示', {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'message-logout',
        })
          .then(() => {
            this.$router.push({ path: '/login' })
          })
          .catch(() => {})
        // this.$message.warning('您还未登录，请先登录！')
      }
    },
    setNetbanCookie() {
      _this.$http
        .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
        .then(data => {
          _this.token = data.data.token
          if (_this.studyed) {
            window.location.href =
              'http://netban.cn/api/user/synwp/' +
              _this.token +
              '?url=http://custom.netban.cn/app/?id=T20326'
          }
        })
    },
    toRedeem() {
      //兑换码
      _this.loading = true
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'exchange', {
          good_id: 1,
          numbers: this.redeemData,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          _this.loading = false
          if (data.data.code == 403 || data.data.code == 500) {
            _this.$message.error(data.data.msg)
            return
          }

          _this.redeem_number = data.data.data
          if (_this.redeem_number) {
            window.location.href =
              'http://pay.netban.cn/pay/' + this.redeem_number //跳转至支付页面
          }
          if (!_this.obj.is_verfy) {
            _this.warningDialog = true
          } else {
            if (_this.toForm.istrue) {
              _this.papers(_this.toForm.istrue)
            } else {
              _this.setNetbanCookie()
            }
          }
          _this.dialogVisible = false
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          _this.loading = false
          console.log(err)
        })
    },
    toPay() {
      //去支付
      this.toExam()
    },
    toExam() {
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'order', {
          good_id: 1,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          if (data.data.code == 200) {
            //未付款
            _this.order_number = data.data.data
            window.location.href =
              'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
            // _this.dialogVisible = true;
          } else if (data.data.code == 201 && data.data.token != '') {
            //已付款
            _this.setNetbanCookie()
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.Nav {
  /deep/.el-row {
    margin: 0px !important;
  }
  background-color: #ffffff;
  .header {
    height: 50px;
    width: 100%;
    position: fixed;
    z-index: 99;
    background-color: #ffffff;
    .tel {
      color: #960f23;
      font-weight: bold;
      vertical-align: middle;
    }
    box-shadow: 0 0 8px #ccc;
  }
  .header_cont {
    padding: 10px 0px 8px;
    .logo_img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .leftimg {
      width: 95% !important;
    }
    .leftimg,
    .rightimg {
      position: relative;
      top: 1px;
    }
    .logo {
      width: 80% !important;
    }
  }
  .menu {
    position: fixed;
    right: 0px;
    width: 28vw;
    z-index: 99;
    background-color: #333333;
    /deep/.el-menu-item {
      height: 40px;
      line-height: 40px;
      color: #ffffff;
    }
    /deep/.el-menu-item:hover {
      background-color: #292929;
      color: #960f23;
    }
    /deep/.el-menu-item.is-active {
      color: #960f23;
      background-color: #292929;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
.Suspended {
  width: 50px;
  height: 50px;
  border: 1px solid #960f23;
  background-color: #960f23;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  z-index: 50;
  bottom: 30px;
  right: 10px;
  p {
    margin-top: 8px;
    color: #ffffff;
    font-size: 12px;
  }
}
#dialog {
  //弹出支付认证
  .el-dialog {
    width: 80vw;
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background-color: #960f23;
      width: 100px;
      margin-top: 50px;
      margin-left: 31%;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 15px 0px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
  .dialog_table {
    .container {
      height: 70vh;
    }
    /deep/.el-dialog__header {
      text-align: center;
      font-weight: bold;
      color: #000000;
    }
    /deep/.el-dialog__body {
      padding: 10px 100px 20px;
    }
  }
  /deep/.el-form-item {
    margin: 0px;
  }
  .warnRed {
    color: red;
    font-size: 13px;
  }
  .payStyle {
    div {
      font-size: 15px;
      margin: 10px;
    }
    span {
      font-size: 16px;
      color: #960f23;
      padding-right: 10px;
    }
    .el-button {
      margin: 30px 30%;
    }
  }
  /deep/.el-form-item__label {
    padding: 10px 0px 0px !important;
  }
  /deep/.el-form-item__error {
    padding-top: 0px;
  }
}
</style>
<style>
.message-logout {
  width: auto !important;
}
</style>
